import { Block } from '../blocks';
import { I18nEntry } from '../i18n';

interface SelectOption {
    label: string;
    value: string;
}

export interface RecipeDesignSeriesEntity {
    id: string;
    name: I18nEntry;
    y: Block[];
    position: number;
    points: SeriesPoint[];
    lineColor: string;
    lineStyle: number;
    repeatXCount?: string;
    repeatXBy?: string;
    repeatYCount?: string;
    repeatYBy?: string;
    condition: Block[];
}

export interface RecipeDesignSeriesDetails {
    id: string;
    name: I18nEntry;
    y: Block[];
    position: number;
    points: SeriesPointRes[];
    lineColor: string;
    lineStyle: number;
    repeatXCount?: SelectOption;
    repeatXBy?: SelectOption;
    repeatYCount?: SelectOption;
    repeatYBy?: SelectOption;
    condition: Block[];
}

export interface RecipeDesignSeriesReq {
    name: I18nEntry;
    designId: string;
    y: Block[];
    points: SeriesPoint[];
    lineColor: string;
    lineStyle: number;
    repeatXCount?: string;
    repeatXBy?: string;
    repeatYCount?: string;
    repeatYBy?: string;
    condition: Block[];
}

export interface SeriesPoint {
    x: string;
    y: string;
}

export interface SeriesPointRes {
    x?: SelectOption;
    y?: SelectOption;
}

export enum LineStyleType {
    SOLID,
    DOTTED,
    DASHED,
    DASHED_WITH_DOT,
    DASHED_WITH_TWO_DOTS,
    DASHED_WITH_THREE_DOTS,
}
