import { FC } from "react";
import { Flex as FlexChakra, FlexProps } from "@chakra-ui/layout";

export const FlexFullscreenCentered: FC<FlexProps> = ({children, ...props}) => (
    <FlexChakra
        width='100vw'
        height='100vh'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        backgroundColor='backgroundApp'
        {...props}
    >
        {children}
    </FlexChakra>
)