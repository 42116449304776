import { createSlice, original, PayloadAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import {
  ComponentDetails,
  I18nEntry,
  OfferRecipeDetails,
  ProductDetails,
  FullRecipeResponse,
  RecipeFieldType,
  RecipeProductDetails,
  RecipeSectionDetails,
  RecipeServiceDetails,
  ServiceDetails,
  RecipeGroupEntity,
  BlockType,
  RecipeComponentEntity,
  RecipeServiceEntity,
  RecipeProductEntity,
  Prices,
  UnitDetails,
  CategoryDetails,
  ProducerDetails
} from 'pol-met-types';

import { Parser as FormulaParser } from 'hot-formula-parser';
import { flattenBlocks } from '../../utils/blocks';
import { CONFIGURATOR_OFFER_MODAL_TYPES } from '../../components/Common/Modals/Patterns/Configurator/Offer';
import { Category } from '../../../../pol-met-back/src/category/category.entity';
import { Unit } from '../../../../pol-met-back/src/unit/unit.entity';

const parser = new FormulaParser();

export interface Visibilities {
  [key: string]: boolean;
}

export interface Values {
  [key: string]: string | number | boolean | null;
}

export interface I18nValues {
  [key: string]: I18nEntry;
}

export interface FieldValue {
  fieldId: string;
  value: string | number | boolean;
}

export interface Quantities {
  [key: string]: number;
}

export interface Percentages {
  [key: string]: number;
}

export interface FieldMarkUp {
  fieldId: string;
  markUp: number;
}

export interface FieldSellingPrice {
  fieldId: string;
  sellingPrice: number;
  originalPrice: number;
}

export interface UpdateQuantity {
  fieldId: string;
  quantity: number;
}

export interface AddProductToOffer {
  product: ProductDetails;
  quantity: number;
  group: RecipeGroupEntity;
}

export interface AddNewProductToOffer {
  name: string;
  quantity: number;
  markUp: number;
  netPrice: number;
  weight: number;
  group: RecipeGroupEntity;
}

export interface AddNewServiceToOffer {
  name: string;
  quantity: number;
  markUp: number;
  netPrice: number;
  group: RecipeGroupEntity;
}

export interface AddServiceToOffer {
  service: ServiceDetails;
  quantity: number;
  group: RecipeGroupEntity;
}

export interface AddComponentToOffer {
  component: ComponentDetails;
  quantity: number;
  group: RecipeGroupEntity;
}

export interface RemoveAdditional {
  type: CONFIGURATOR_OFFER_MODAL_TYPES | undefined;
  id: string;
  group: RecipeGroupEntity;
}

export interface ToggleElement {
  id: string;
  group: RecipeGroupEntity;
}

export interface SetRecipe {
  recipe?: FullRecipeResponse;
  isInitialized: boolean;
}

export interface VisualizationState {
  recipe: FullRecipeResponse | null;
  calculationUuid: string;
  sectionVisibility: Visibilities;
  groupVisibility: Visibilities;
  infoValues: Values;
  infoVisibility: Visibilities;
  fieldValues: Values;
  fieldOptions: Values;
  fieldVisibility: Visibilities;
  quantities: Quantities;
  itemVisibility: Visibilities;
  itemInactivity: Visibilities;
  markUp: Percentages;
  // overriden buy prices of products
  buyPrices: Prices;
  // temporary form state - later it is saved to main store by debouncing
  formValues: Values;
}

const initialState: VisualizationState = {
  recipe: null,
  calculationUuid: v4(),
  sectionVisibility: {},
  groupVisibility: {},
  infoValues: {},
  infoVisibility: {},
  fieldValues: {},
  fieldVisibility: {},
  quantities: {},
  itemVisibility: {},
  itemInactivity: {},
  fieldOptions: {},
  markUp: {},
  buyPrices: {},
  formValues: {}
};

export const visualizationSlice = createSlice({
  name: 'visualization',
  initialState,
  reducers: {
    setRecipeState: (state, action: PayloadAction<OfferRecipeDetails>) => {
      const offerRecipe = action.payload;
      state.recipe = offerRecipe.recipe;
      state.calculationUuid = offerRecipe.calculationUuid;
      state.sectionVisibility = offerRecipe.sectionVisibility;
      state.groupVisibility = offerRecipe.groupVisibility;
      state.infoValues = offerRecipe.infoValues;
      state.infoVisibility = offerRecipe.infoVisibility;
      state.fieldValues = offerRecipe.fieldValues;
      state.quantities = offerRecipe.quantities;
      state.itemVisibility = offerRecipe.itemVisibility;
      state.itemInactivity = offerRecipe.itemInactivity || {};
      state.fieldOptions = offerRecipe.fieldOptions;
      state.markUp = offerRecipe.markUp;
      state.quantities = offerRecipe.quantities;
      state.buyPrices = offerRecipe.buyPrices || {};
    },
    setRecipe: (state, action: PayloadAction<FullRecipeResponse | null>) => {
      let recipe: FullRecipeResponse | null = action.payload;
      // IF RECIPE IS NULL THEN CLEAT STATE
      if (!recipe) return initialState;
      const initialMarkUp: Percentages = {};
      const options: Values = {};
      const initialFieldValues: Values = {};

      recipe.inputs.forEach(input => {
        if (input.options) {
          input.options.forEach(option => {
            options[option.uuid] = option.value;
          })
        }
        if (input.type === RecipeFieldType.NUMBER) {
          initialFieldValues[input.id] = 0;
        }
      })

      recipe.groups.forEach(group => {
        group.products.forEach(recipeProduct => {
          initialMarkUp[recipeProduct.id] = recipeProduct.product.markUp;
        })
        group.services.forEach(recipeService => {
          initialMarkUp[recipeService.id] = recipeService.service.markUp;
        })
        group.components.forEach(recipeComponent => {
          recipeComponent.component.products.forEach(componentProduct => {
            if (!componentProduct.id) return;
            initialMarkUp[componentProduct.id] = componentProduct.product.markUp;
          })
          recipeComponent.component.services.forEach(componentService => {
            if (!componentService.id) return;
            initialMarkUp[componentService.id] = componentService.service.markUp;
          })

        })
      })

      state.markUp = initialMarkUp;
      state.recipe = recipe;
      state.fieldOptions = options;
      state.fieldValues = initialFieldValues;
    },
    setFieldValue: (state, action: PayloadAction<FieldValue>) => {
      const { fieldId, value } = action.payload;

      if (state.fieldValues[fieldId] === value) return;

      state.formValues = {
        ...state.formValues,
        [fieldId]: value,
      };
    },
    setFieldValues: (state) => {
      state.calculationUuid = v4();
      state.fieldValues = {
        ...state.fieldValues,
        ...state.formValues,
      }
    },
    updateItemMarkUp: (state, action: PayloadAction<FieldMarkUp>) => {
      state.markUp = {
        ...state.markUp,
        [action.payload.fieldId]: Math.round(action.payload.markUp * 100) / 100,
      }
    },
    updateItemSellingPrice: (state, action: PayloadAction<FieldSellingPrice>) => {
      const { sellingPrice, originalPrice } = action.payload;
      const newMarkup = Math.round(((sellingPrice - originalPrice) / originalPrice) * 100 * 10000) / 10000;
      state.markUp = {
        ...state.markUp,
        [action.payload.fieldId]: newMarkup
      }
    },
    updateItemBuyPrice: (state, action: PayloadAction<FieldSellingPrice>) => {
      const { originalPrice } = action.payload;

      state.buyPrices = {
        ...state.buyPrices,
        [action.payload.fieldId]: originalPrice
      }
    },
    updateItemQuantity: (state, action: PayloadAction<UpdateQuantity>) => {
      const { fieldId, quantity } = action.payload;

      state.quantities = {
        ...state.quantities,
        [fieldId]: Math.round(quantity * 100) / 100
      }
    },
    removeAdditional: (state, action: PayloadAction<RemoveAdditional>) => {
      if (!state.recipe) return;

      state.recipe.groups = state.recipe?.groups.map((_group) => {
        if (_group.id === action.payload.group.id) {
          if (action.payload.type === CONFIGURATOR_OFFER_MODAL_TYPES.PRODUCTS) return { ..._group, products: _group.products.filter(groupProduct => groupProduct.id !== action.payload.id) };
          if (action.payload.type === CONFIGURATOR_OFFER_MODAL_TYPES.SERVICES) return { ..._group, services: _group.services.filter(groupService => groupService.id !== action.payload.id) };
          if (action.payload.type === CONFIGURATOR_OFFER_MODAL_TYPES.COMPONENTS) return { ..._group, components: _group.components.filter(groupComponent => groupComponent.id !== action.payload.id) };
        }
        return _group;
      });

    },
    toggleElement: (state, action: PayloadAction<ToggleElement>) => {
      if (!state.recipe) return;

      const newActivity = !state.itemInactivity[action.payload.id];

      state.itemInactivity = {
        ...state.itemInactivity,
        [action.payload.id]: newActivity
      }


    },
    addProductToOffer: (state, action: PayloadAction<AddProductToOffer>) => {
      const newUuid = v4();
      const { product, quantity, group } = action.payload;

      state.quantities = {
        ...state.quantities,
        [newUuid]: quantity,
      };

      state.itemVisibility = {
        ...state.itemVisibility,
        [newUuid]: true,
      };
      state.markUp = {
        ...state.markUp,
        [newUuid]: (Math.round(product.markUp * 100) / 100) || 0,
      }

      if (state.recipe) {
        state.recipe.groups = state.recipe?.groups.map((_group) => {
          if (_group.id === group.id) {
            const newProduct: RecipeProductEntity = {
              id: newUuid,
              product: {
                ...product,
                isAdditional: true,
              },
              position: Number(_group.products[_group.products.length - 1] || 1),
              quantity: [
                {
                  type: BlockType.VALUE,
                  uuid: newUuid,
                  value: quantity,
                },
              ],
              condition: [],
              markUp: [],
            };
            return {
              ..._group,
              products: [
                ..._group.products,
                { ...newProduct }
              ]
            }
          }
          return _group;
        })
      }
    },
    addNewProductToOffer: (state, action: PayloadAction<AddNewProductToOffer>) => {
      const newUuid = v4();
      const productId = v4();
      const { name, markUp, netPrice, quantity, group, weight } = action.payload;

      state.quantities = {
        ...state.quantities,
        [newUuid]: quantity,
      };

      state.itemVisibility = {
        ...state.itemVisibility,
        [newUuid]: true,
      };
      state.markUp = {
        ...state.markUp,
        [newUuid]: (Math.round(markUp * 100) / 100) || 0,
      }

      if (state.recipe) {
        state.recipe.groups = state.recipe?.groups.map((_group) => {
          if (_group.id === group.id) {
            const newProduct: RecipeProductEntity = {
              id: newUuid,
              product: {
                id: productId,
                name: {
                  'pl': name
                },
                description: { pl: '' },
                unit: {
                  id: '',
                  name: { pl: '' },
                  type: ''
                } as UnitDetails,
                category: {} as CategoryDetails,
                producer: '',
                buyPrice: netPrice,
                currentPrice: netPrice,
                markUp: markUp,
                isAdditional: true,
                weight,
                position: Number(_group.products[_group.products.length - 1] || 1),
              },
              position: Number(_group.products[_group.products.length - 1] || 1),
              quantity: [
                {
                  type: BlockType.VALUE,
                  uuid: newUuid,
                  value: quantity,
                },
              ],
              condition: [],
              markUp: [],
            };
            return {
              ..._group,
              products: [
                ..._group.products,
                { ...newProduct }
              ]
            }
          }
          return _group;
        })
      }
    },
    addNewServiceToOffer: (state, action: PayloadAction<AddNewServiceToOffer>) => {
      const newUuid = v4();
      const productId = v4();
      const { name, markUp, netPrice, quantity, group } = action.payload;

      state.quantities = {
        ...state.quantities,
        [newUuid]: quantity,
      };

      state.itemVisibility = {
        ...state.itemVisibility,
        [newUuid]: true,
      };
      state.markUp = {
        ...state.markUp,
        [newUuid]: (Math.round(markUp * 100) / 100) || 0,
      }

      if (state.recipe) {
        state.recipe.groups = state.recipe?.groups.map((_group) => {
          if (_group.id === group.id) {
            const newService: RecipeServiceEntity = {
              id: newUuid,
              service: {
                id: productId,
                name: {
                  'pl': name
                },
                description: { pl: '' },
                unit: {
                  id: '',
                  name: { pl: '' },
                  type: ''
                } as UnitDetails,
                category: {} as CategoryDetails,
                cost: netPrice,
                currentCost: netPrice,
                markUp: markUp,
                isAdditional: true,
                position: Number(_group.services[_group.services.length - 1] || 1),
              },
              position: Number(_group.services[_group.services.length - 1] || 1),
              quantity: [
                {
                  type: BlockType.VALUE,
                  uuid: newUuid,
                  value: quantity,
                },
              ],
              condition: [],
              markUp: [],
            };
            return {
              ..._group,
              services: [
                ..._group.services,
                { ...newService }
              ]
            }
          }
          return _group;
        })
      }
    },

    addServiceToOffer: (state, action: PayloadAction<AddServiceToOffer>) => {
      const newUuid = v4();
      const { service, quantity, group } = action.payload;

      state.quantities = {
        ...state.quantities,
        [newUuid]: quantity,
      };

      state.itemVisibility = {
        ...state.itemVisibility,
        [newUuid]: true,
      };

      state.markUp = {
        ...state.markUp,
        [newUuid]: (Math.round(service.markUp * 100) / 100) || 0,
      }

      if (state.recipe) {
        state.recipe.groups = state.recipe?.groups.map((_group) => {
          if (_group.id === group.id) {
            const newService: RecipeServiceEntity = {
              id: newUuid,
              service: {
                ...service,
                isAdditional: true,
                category: service.category as Category,
                unit: service.unit as Unit,
              },
              position: Number(_group.services[_group.services.length - 1] || 1),
              quantity: [
                {
                  type: BlockType.VALUE,
                  uuid: newUuid,
                  value: quantity,
                },
              ],
              condition: [],
              markUp: [],
            };
            return {
              ..._group,
              services: [
                ..._group.services,
                { ...newService },
              ]
            };
          }
          return _group;
        })
      }
    },
    addComponentToOffer: (state, action: PayloadAction<AddComponentToOffer>) => {
      const newUuid = v4();
      const { component, quantity, group } = action.payload;

      component.products = component.products.map((item) => {
        const newUuidProduct = v4();
        if (item.quantity) {
          state.quantities = {
            ...state.quantities,
            [newUuidProduct]: item.quantity,
          };
        }
        state.markUp = {
          ...state.markUp,
          [newUuidProduct]: item.product?.markUp || 0,
        }

        return { ...item, id: newUuidProduct };
      });

      component.services = component.services.map((item) => {
        const newUuidService = v4();
        if (item.quantity) {
          state.quantities = {
            ...state.quantities,
            [newUuidService]: item.quantity,
          };
        }
        state.markUp = {
          ...state.markUp,
          [newUuidService]: item.service.markUp || 0,
        }

        return { ...item, id: newUuidService };
      });

      // COMPONENT QUANTITY MARKUP AND VISIBILITY
      state.quantities = {
        ...state.quantities,
        [newUuid]: quantity,
      };

      state.markUp = {
        ...state.markUp,
        [newUuid]: 0,
      };

      state.itemVisibility = {
        ...state.itemVisibility,
        [newUuid]: true,
      };

      if (state.recipe) {
        state.recipe.groups = state.recipe?.groups.map((_group) => {
          if (_group.id === group.id) {
            const newComponent: RecipeComponentEntity = {
              id: newUuid,
              component: {
                ...component,
                isAdditional: true,
              },
              position: Number(_group.components[_group.components.length - 1] || 1),
              quantity: [
                {
                  type: BlockType.VALUE,
                  uuid: newUuid,
                  value: quantity,
                },
              ],
              condition: [],
              markUp: [],
            };
            return {
              ..._group,
              components: [
                ..._group.components,
                { ...newComponent },
              ]
            };
          }
          return _group;
        })
      }
    },
    refreshData: (state, action: PayloadAction<SetRecipe>) => {
      const { recipe, isInitialized } = action.payload;

      if (!recipe) {
        return;
      }
      // to determine if another run is necessary to update related inputs/fields/etc
      let anyChange = false;

      let newSectionVisibility: Visibilities = state.sectionVisibility;
      let newGroupVisibility: Visibilities = state.groupVisibility;
      let newInfoValues: Values = state.infoValues;
      let newInfoVisibilities: Visibilities = state.infoVisibility;
      let newFieldValues: Values = state.fieldValues;
      let newFieldVisibilities: Visibilities = state.fieldVisibility;
      let newQuantities: Quantities = state.quantities;
      let newItemVisibilities: Visibilities = state.itemVisibility;
      let newMarkUp: Percentages = state.markUp;
      let newUuid = state.calculationUuid;

      let mappedNames: I18nValues = {};
      [...recipe.infos, ...recipe.inputs].forEach((val) => {
        // Changed from val.title
        mappedNames[val.id] = val.name;
      });

      do {
        anyChange = false;
        const allValues = {
          ...newFieldValues,
          ...newInfoValues,
        };
        // POLMET-297 Add access to infos values outside sections
        recipe.infos.forEach((info) => {
          if (info.value) {

            const value = info.value || [];
            const formulaValue = flattenBlocks(
              value,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              state.fieldOptions,
              mappedNames
            );

            const result = parser.parse(formulaValue).result;

            if (
              result !== newInfoValues[info.id]
            ) {
              anyChange = true;
              newInfoValues = {
                ...newInfoValues,
                [info.id]: result,
              };
            } else if (!newInfoValues[info.id] && newInfoValues[info.id] !== 0) {
              newInfoValues = {
                ...newInfoValues,
                [info.id]: null,
              };
            }
          }
        });

        // RECIPE SECTIONS
        recipe.sections.forEach((section: RecipeSectionDetails) => {
          // SECTION CONDITION

          let shouldSectionBeVisible = true;

          // section can be hidden
          if (section.condition && section.condition.length > 0) {
            const formulaVisibility = flattenBlocks(
              section.condition,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              state.fieldOptions,
              mappedNames
            );

            const result = parser.parse(formulaVisibility).result;

            shouldSectionBeVisible = !!result;

          }

          newSectionVisibility = {
            ...newSectionVisibility,
            [section.id]: section.condition!.length > 0 ? shouldSectionBeVisible : true,
          };

          // SECTION INFO FIELD
          section.infos.forEach((info) => {
            // if fields visibility can be toggled
            if (info.condition) {
              const condition = info.condition || [];
              const formulaVisibility = flattenBlocks(
                condition,
                '',
                { ...allValues, ...newFieldValues, ...newInfoValues },
                state.fieldOptions,
                mappedNames
              );

              const result = parser.parse(formulaVisibility).result;



              newInfoVisibilities = {
                ...newInfoVisibilities,
                [info.id]: condition!.length > 0 ? result : true,
              };
            } else {
              newInfoVisibilities = {
                ...newInfoVisibilities,
                [info.id]: true,
              };
            }

          });

          // SECTION INPUT FIELDS
          section.inputs.forEach((input) => {
            const isSectionVisible = newSectionVisibility[section.id];
            let shouldInputBeVisible = isSectionVisible;

            // if fields visibility can be toggled
            if (isSectionVisible && input.condition && input.condition.length > 0) {
              const formulaVisibility = flattenBlocks(
                input.condition,
                '',
                { ...allValues, ...newFieldValues, ...newInfoValues },
                state.fieldOptions,
                mappedNames
              );

              const result = parser.parse(formulaVisibility).result;
              shouldInputBeVisible = !!result;
            }

            if (isInitialized) {
              // if field is currently visible but should be hidden
              if (shouldInputBeVisible === false && newFieldVisibilities[input.id] === true) {
                anyChange = true;
                newFieldValues = {
                  ...newFieldValues,
                  [input.id]:
                    input.type === RecipeFieldType.CHECKBOX ? false :
                      input.type === RecipeFieldType.NUMBER ? 0 : null,
                };
              }
              // if field is currently hidden but should be visible
              else if (isInitialized && shouldInputBeVisible === true && newFieldVisibilities[input.id] !== true) {
                anyChange = true;
                if ([RecipeFieldType.RADIOBUTTONS, RecipeFieldType.SELECT].includes(input.type) && input.options) {
                  newFieldValues = {
                    ...newFieldValues,
                    [input.id]: input.options[0].value
                  };
                }
              }
            }
            newFieldVisibilities = {
              ...newFieldVisibilities,
              [input.id]: shouldInputBeVisible,
            };
          });

        });

        // RECIPE GROUPS
        recipe.groups.forEach((group) => {
          if (group.condition) {
            const formulaVisibility = flattenBlocks(
              group.condition,
              '',
              { ...allValues, ...newFieldValues, ...newInfoValues },
              state.fieldOptions,
              mappedNames
            );

            newGroupVisibility = {
              ...newGroupVisibility,
              [group.id]:
                group.condition!.length > 0
                  ? parser.parse(formulaVisibility).result
                  : true,
            };
          }

          // RECIPE PRODUCTS SERVICES
          [...group.products, ...group.services].forEach((item) => {
            if (item.quantity && isInitialized) {
              const formulaQuantity = flattenBlocks(
                item.quantity,
                '',
                { ...allValues, ...newFieldValues, ...newInfoValues },
                state.fieldOptions,
                mappedNames
              );

              newQuantities = {
                ...newQuantities,
                [item.id]: Math.round(parser.parse(formulaQuantity).result * 100) / 100,
              };
            }
            if (item.condition) {
              const formulaVisibility = flattenBlocks(
                item.condition,
                '',
                { ...allValues, ...newFieldValues, ...newInfoValues },
                state.fieldOptions,
                mappedNames
              );

              newItemVisibilities = {
                ...newItemVisibilities,
                [item.id]:
                  item.condition!.length > 0
                    ? parser.parse(formulaVisibility).result
                    : true,
              };
            }

            // MARKUP
            if (!state.markUp[item.id]) {
              const formulaMarkUp = flattenBlocks(
                item.markUp,
                '',
                { ...allValues, ...newFieldValues, ...newInfoValues },
                state.fieldOptions,
                mappedNames
              );
              const result = parser.parse(formulaMarkUp).result;

              if ((item as RecipeProductDetails).product) {
                newMarkUp = {
                  ...newMarkUp,
                  [item.id]: item.markUp!.length > 0 ? result
                    : typeof newMarkUp[(item as RecipeProductDetails).id] !== undefined
                      ? newMarkUp[(item as RecipeProductDetails).id]
                      : (item as RecipeProductDetails).product.markUp || 0,
                };
              } else if ((item as RecipeServiceDetails).service) {
                newMarkUp = {
                  ...newMarkUp,
                  [item.id]: item.markUp!.length > 0 ? result
                    : typeof newMarkUp[(item as RecipeServiceDetails).id] !== undefined
                      ? newMarkUp[(item as RecipeServiceDetails).id]
                      : (item as RecipeServiceDetails).service.markUp || 0,
                };
              }
            }
          });

          // COMPONENTS
          group.components.forEach((component) => {
            // COMPONENT QUANTITY
            if (component.quantity) {
              const formulaQuantity = flattenBlocks(
                component.quantity,
                '',
                { ...allValues, ...newFieldValues, ...newInfoValues },
                state.fieldOptions,
                mappedNames
              );

              newQuantities = {
                ...newQuantities,
                [component.id]: Math.round(parser.parse(formulaQuantity).result * 100) / 100,
              };
            }
            // COMPONENT MARKUP
            if (component.markUp) {
              const formulaMarkUp = flattenBlocks(
                component.markUp,
                '',
                { ...allValues, ...newFieldValues, ...newInfoValues },
                state.fieldOptions,
                mappedNames
              );

              newMarkUp = {
                ...newMarkUp,
                [component.id]: parser.parse(formulaMarkUp).result || 0,
              };
            }
            // COMPONENT CONDITION
            if (component.condition) {
              const formulaVisibility = flattenBlocks(
                component.condition,
                '',
                { ...allValues, ...newFieldValues, ...newInfoValues },
                state.fieldOptions,
                mappedNames
              );

              newItemVisibilities = {
                ...newItemVisibilities,
                [component.id]:
                  component.condition!.length > 0
                    ? parser.parse(formulaVisibility).result
                    : true,
              };
            }
            // COMPONENT PRODUCTS
            component.component.products.forEach((item) => {
              if (item.id) {
                if (item.quantity && isInitialized) {
                  newQuantities = {
                    ...newQuantities,
                    [item.id]: item.quantity,
                  };
                }



                newMarkUp = {
                  ...newMarkUp,
                  [item.id]: typeof newMarkUp[item.id] !== undefined
                    ? newMarkUp[item.id] : item.product.markUp || 0
                }
              }
            });
            // COMPONENT SERVICES
            component.component.services.forEach((item) => {
              if (item.id) {
                if (item.quantity && isInitialized) {
                  newQuantities = {
                    ...newQuantities,
                    [item.id]: item.quantity,
                  };
                }

                newMarkUp = {
                  ...newMarkUp,
                  [item.id]: typeof newMarkUp[item.id] !== undefined
                    ? newMarkUp[item.id] : item.service.markUp || 0
                }
              }
            });
          });


        });
      } while (anyChange)

      state.sectionVisibility = newSectionVisibility;
      state.groupVisibility = newGroupVisibility;
      state.infoValues = newInfoValues;

      state.infoVisibility = newInfoVisibilities;

      state.fieldValues = newFieldValues;
      if (anyChange) {
        state.formValues = { ...state.formValues, ...newFieldValues };
      }
      state.fieldVisibility = newFieldVisibilities;
      state.calculationUuid = newUuid;
      state.quantities = newQuantities;
      state.itemVisibility = newItemVisibilities;
      state.markUp = newMarkUp;
    },
  },
});

export const {
  setRecipe,
  setFieldValue,
  setFieldValues,
  updateItemMarkUp,
  updateItemSellingPrice,
  updateItemBuyPrice,
  updateItemQuantity,
  refreshData,
  addProductToOffer,
  addServiceToOffer,
  addNewProductToOffer,
  addNewServiceToOffer,
  addComponentToOffer,
  removeAdditional,
  toggleElement,
  setRecipeState,
} = visualizationSlice.actions;

export default visualizationSlice.reducer;
