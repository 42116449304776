import { FC } from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { GroupBase, OptionsOrGroups } from 'react-select';
import { __ } from '../../../helpers/i18n';
import { BaseReactSelectOption } from '../../../types/forms';
import { useAsyncDebounce } from 'react-table';
import {
  disabledInput,
  customPrefixStylesForSelectSearchable,
  customStylesForSelectSearchable,
} from '../../../config/theme';
import { Controller, useFormContext } from 'react-hook-form';
import { useFormFieldContext } from '../../../hooks/form/useFormFieldContext';

interface Props {
  promiseOptions: any;
  isDisabled?: boolean;
  options?: OptionsOrGroups<
    BaseReactSelectOption,
    GroupBase<BaseReactSelectOption>
  >;
  handleChange?: (selected?: any) => void;
  handleCreate?: any;
  isMulti?: boolean;
  isOnlySearch?: boolean;
  emptyInput?: boolean;
  isClearable?: boolean;
  isCreatable?: boolean;
  placeholder?: string;
  value?: BaseReactSelectOption | null;
  defaultValue?: string | number | BaseReactSelectOption | null;
  defaultOptions?: any | BaseReactSelectOption;
}

// eslint-disable-next-line import/prefer-default-export
export const InputSelectSearchable: FC<Props> = ({
  isOnlySearch,
  emptyInput,
  isClearable,
  promiseOptions,
  handleChange,
  options,
  isMulti,
  handleCreate,
  placeholder,
  isDisabled,
  value,
  defaultValue,
  isCreatable,
  defaultOptions,
  ...props
}) => {
  const { control, trigger } = useFormContext();
  const { name, isRequired, id } = useFormFieldContext();
  const loadOptions = useAsyncDebounce((debValue: string, cb: Function) => {
    return promiseOptions(debValue, cb);
  }, 500);
  if (isOnlySearch)
    return (
      <AsyncSelect
        cacheOptions
        defaultOptions={defaultOptions}
        styles={customStylesForSelectSearchable}
        isMulti={isMulti}
        noOptionsMessage={() => __('application.noOptions')}
        onChange={(e) => {
          handleChange?.(e);
        }}
        loadOptions={loadOptions}
        options={options ?? []}
        placeholder={placeholder ? __(placeholder) : __('action.search')}
        loadingMessage={() => __('application.loading')}
        value={value ?? null}
        className={`${customPrefixStylesForSelectSearchable} ${
          isDisabled ? disabledInput : undefined
        }`}
        classNamePrefix='custom-theme-styles'
        {...props}
        menuPortalTarget={document.body}
        menuPlacement="auto"
      />
    );
  return (
    <Controller
      rules={{ required: isRequired }}
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field, ...fieldProps }) => {
        return isCreatable ? (
          <AsyncCreatableSelect
            cacheOptions
            defaultOptions={defaultOptions}
            inputId={id || name}
            isMulti={isMulti}
            noOptionsMessage={() => __('application.noOptions')}
            onChange={(e: BaseReactSelectOption) => {
              field.onChange(e);
              handleChange?.(e);
              trigger(name);
            }}
            onCreateOption={(e) => {
              if (isMulti) {
                if (field?.value?.length > 0) {
                  field.onChange([...field.value, { value: e, label: e }]);
                } else {
                  field.onChange([{ value: e, label: e }]);
                }
              } else {
                field.onChange({ value: e, label: e });
              }
              handleCreate?.(e);
            }}
            formatCreateLabel={(option: any) =>
              `${__('action.create')} "${option}"`
            }
            controlShouldRenderValue={emptyInput}
            isClearable={isClearable}
            loadOptions={loadOptions}
            options={options ?? []}
            placeholder={placeholder ? __(placeholder) : __('action.search')}
            loadingMessage={() => __('application.loading')}
            value={field.value ?? value ?? null}
            styles={customStylesForSelectSearchable}
            className={`${customPrefixStylesForSelectSearchable} ${
              isDisabled ? disabledInput : undefined
            }`}
            classNamePrefix='custom-theme-styles'
            {...props}
            menuPortalTarget={document.body}
            menuPlacement="auto"
          />
        ) : (
          <AsyncSelect
            cacheOptions
            defaultOptions={defaultOptions}
            inputId={id || name}
            isMulti={isMulti}
            noOptionsMessage={() => __('application.noOptions')}
            onChange={(e: BaseReactSelectOption) => {
              field.onChange(e);
              handleChange?.(e);
              trigger(name);
            }}
            controlShouldRenderValue={emptyInput}
            isClearable={isClearable}
            loadOptions={loadOptions}
            options={options ?? []}
            placeholder={placeholder ? __(placeholder) : __('action.search')}
            loadingMessage={() => __('application.loading')}
            value={field.value ?? value ?? null}
            styles={customStylesForSelectSearchable}
            className={`${customPrefixStylesForSelectSearchable} ${
              isDisabled ? disabledInput : undefined
            }`}
            classNamePrefix='custom-theme-styles'
            {...props}
            menuPortalTarget={document.body}
            menuPlacement="auto"
          />
        );
      }}
    />
  );
};
