import { FC } from 'react';

import { TransFn } from '../../../../types/general';
import { __ } from '../../../../helpers/i18n';
import { Heading } from '../Heading';

interface Props {
  text?: string | TransFn;
  children?: React.ReactNode;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
}

export const HeaderTitle: FC<Props> = ({ text, children, level = 2 }) => (
  <Heading level={level}>
    {text && (typeof text === 'string' ? __(text) : __(text.key, text.options))}
    {children ?? null}
  </Heading>
);
