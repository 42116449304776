import React from "react";
import { Button } from "@chakra-ui/button";
import { Box } from "@chakra-ui/react";
import { Text } from "../../components/Common/Text";

const Error = () => (
  <Box
    display='flex'
    alignItems='center'
    justifyContent='center'
    flexDirection='column'
  >
    <Text color='red.900'>Błąd</Text>
    <Button
      onClick={() => {
        window.location.reload();
      }}
    >
        Odśwież
    </Button>
  </Box>
);

export default Error;
