import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { handleError } from '../helpers/handleError';
import { axiosRequestConfiguration } from '../config/axios';
import { UserMeDetails } from 'pol-met-types';
import { ApiPayload } from '../hooks/api/useApi';

export interface UserState {
  details: UserMeDetails | undefined;
  inProgress: boolean;
  isError: boolean;
  isLoaded: boolean;
  logoutInProgress: boolean;
};

const initialState: UserState = {
  details: undefined,
  inProgress: false,
  isError: false,
  isLoaded: false,
  logoutInProgress: false,
};

const axiosInstance = axios.create(axiosRequestConfiguration);

export const getUserDetails = createAsyncThunk('user/details', async () => {
  const user = await axiosInstance
    .get<ApiPayload<Promise<UserMeDetails>>>('user/details')
    .then((res) => res.data.payload)
    .catch((err) => {
      handleError(err.response);
    });
  return user as UserMeDetails;
});

export const logout = createAsyncThunk('auth/logout', async () => {
  return axiosInstance
    .get('auth/logout')
    .then(() => {})
    .catch((err) => handleError(err))
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.details = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.pending, (state) => {
      state.inProgress = true;
      state.isLoaded = false;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.inProgress = false;
      state.isLoaded = true;
      state.details = action.payload;
    });
    builder.addCase(getUserDetails.rejected, (state) => {
      state.inProgress = false;
      state.isLoaded = true;
      state.isError = true;
    });
    builder.addCase(logout.pending, (state) => {
      state.logoutInProgress = true;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.logoutInProgress = false;
      state.details = undefined;
    });
    builder.addCase(logout.rejected, (state) => {
      state.logoutInProgress = false;
    });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
