import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserRole } from 'pol-met-types';
import { useSelector } from 'react-redux';
import { State } from '../../config/store';

interface Props {
    children: React.ReactElement;
    roles: UserRole[];
}

const RouteWithRole = ({ children, roles }: Props) => {
    const user = useSelector((state: State) => state.user.details);

    if (!user) {
        return <Navigate to='/' />
    }

    return children;
}

export { RouteWithRole };