import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecipeDetails } from 'pol-met-types';

export interface OfferState {
  recipe: RecipeDetails | null;
}

const initialState: OfferState = {
  recipe: null,
};

export const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    setRecipe: (state, action: PayloadAction<RecipeDetails | null>) => {
      state.recipe = action.payload;
    },
  },
});

export const {
  setRecipe,
} = offerSlice.actions;

export default offerSlice.reducer;
