import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConfigurationModalType, Methods, ConfigurationDataType } from '../../types/configurator';
import { __ } from '../../helpers/i18n';

export interface ConfiguratorModalState {
  title: string;
  data: any;
  additionalData: any;
  url: string | null;
  method: Methods | null;
  configurationModalType: ConfigurationModalType | null;
  configurationDataType: ConfigurationDataType | null;
  refreshData?: ConfigurationDataType | null;
};

const initialState: ConfiguratorModalState = {
  title: '',
  data: null,
  additionalData: null,
  url: null,
  method: null,
  configurationModalType: null,
  configurationDataType: null,
  refreshData: null,
};

const configuratorModalSlice = createSlice({
  name: 'configuratorModal',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<ConfiguratorModalState>) => {
      state.title = action.payload.title;
      state.data = action.payload.data;
      state.additionalData = action.payload.additionalData;
      state.url = action.payload.url;
      state.method = action.payload.method;
      state.configurationDataType = action.payload.configurationDataType;
      state.configurationModalType = action.payload.configurationModalType;
    },
    refreshData: (state, action: PayloadAction<ConfigurationDataType | null>) => {
      state.refreshData = action.payload;
    },
    clearResolving: () => initialState,
  },
});

export const {
  setData,
  clearResolving,
  refreshData,
} = configuratorModalSlice.actions;

export default configuratorModalSlice.reducer;
