import { Action, configureStore } from '@reduxjs/toolkit';
import appStatus from '../slices/appStatusSlice';
import lang from '../slices/langSlice';
import user from '../slices/userSlice';
import appModal from '../slices/appModalSlice';
import configuratorModal from '../slices/modals/configuratorModalSlice';
import adminModal from '../slices/modals/adminModalSlice';
import visualization from '../slices/configurator/visualisationSlice';
import offer from '../slices/configurator/offerSlice';

const middlewareConfiguration = { serializableCheck: false };

export const store = configureStore({
    reducer: {
        // Slices here
        appStatus,
        lang,
        user,
        appModal,
        configuratorModal,
        adminModal,
        visualization,
        offer,
    },
    devTools: {
        name: 'Pol-met',
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(middlewareConfiguration),
});
export type State = ReturnType<typeof store.getState>;

export function dispatchOnCall(action: Action) {
    return () => store.dispatch(action);
}