import { __ } from './i18n';
import 'yup-phone';
import { store } from '../config/store';
import { ContextType, StringField, YupObject, YupString, YupType } from '../types/yup';
import AppConfig from '../config/app';

export const checkPassword = (yup: YupType) =>
    yup
        .string()
        .required(__('error.fieldIsRequired'))
        .min(8, __('error.textToShort', { value: '8' }))
        .max(40, __('error.textToLong', { value: '40' }));

export const checkRepeatedPassword = (yup: YupType) =>
    yup
        .string()
        .oneOf([yup.ref('password')], __('error.passwordsMustMatch'))
        .min(8, __('error.textToShort', { value: '8' }))
        .max(40, __('error.textToLong', { value: '40' }));

export const numberInput = (yup: YupType) =>
    yup
        .number()
        .nullable()
        .integer(__('error.mustBeInteger'))
        .typeError(__('error.mustBeAValidNumber'));

const maxTwoDigitsAfterDecimals = (value?: number | null) =>
    `${value}`.split('.')[1] ? `${value}`.split('.')[1].length <= 2 : true;

export const priceNumber = (yup: YupType) =>
    yup
        .number()
        .nullable()
        .typeError(__('error.mustBeAValidNumber'))
        .min(0.01, __('error.numberToSmall', { value: 0.01 }))
        .test('max2DigitsAfterDecimal', __('error.provideCorrectPrice'), maxTwoDigitsAfterDecimals);

export const weightNumber = (yup: YupType) =>
    yup
        .number()
        .nullable()
        .typeError(__('error.mustBeAValidNumber'))
        .min(0.01, __('error.numberToSmall', { value: 0.01 }))
        .test('max2DigitsAfterDecimal', __('error.provideCorrectWeight'), maxTwoDigitsAfterDecimals);

export const quantityAndIdArray = (yup: YupType) =>
    yup
        .array()
        .of(
            yup.object().shape({
                id: yup.string(),
                quantity: yup.number().nullable().moreThan(0, __('error.valueMustBeGreaterThan0')).typeError(__('error.mustBeAValidNumber')),
            })
        )

export const reactSelectOption = (yup: YupType) =>
    yup
        .object().shape({
            label: yup.string(),
            value: yup.mixed()
        })

export const phoneNumber = (yup: YupType) => {
    const currentLang = store.getState().lang.current;
    return yup
        .string()
        .phone(currentLang, undefined, __('error.invalidPhoneNumber'))
}

export const categoryDetails = (yup: YupType) =>
    yup
        .object().shape({
            name: requiredI18nValidator(yup, yup.string()),
            nr: yup.string(),
            position: yup.number(),
            parentCategoryId: yup.string(),
        })

export const unitDetails = (yup: YupType) =>
    yup.object().shape({

    })


export const offerProductDetails = (yup: YupType) =>
    yup.object().shape({
        id: yup.string(),
        name: requiredI18nValidator(yup, yup.string()),
        description: requiredI18nValidator(yup, yup.string()),
        unit: yup.object().shape({
            id: yup.string(),
            name: requiredI18nValidator(yup, yup.string()),
            type: yup.string(),
        }),
        category: categoryDetails(yup),
        producer: yup.string(),
        buyPrice: yup.number(),
        netPrice: yup.number(),
        region: yup.string(),
        city: yup.string(),
        markUp: yup.number(),
        active: yup.boolean(),
        weight: yup.number(),
        quantity: yup.number(),
        currentPrice: yup.number(),
    })

export const offerServiceDetails = (yup: YupType) =>
    yup.object().shape({
        id: yup.string(),
        name: requiredI18nValidator(yup, yup.string()),
        description: requiredI18nValidator(yup, yup.string()),
        unit: yup.object().shape({
            id: yup.string(),
            name: requiredI18nValidator(yup, yup.string()),
            type: yup.string(),
        }),
        category: categoryDetails(yup),
        netPrice: yup.number(),
        markUp: yup.number(),
        active: yup.boolean(),
        quantity: yup.number(),
        cost: yup.number(),
    })

export const requiredI18nValidator = (
    yup: YupType,
    yupValidator: YupString,
): YupObject => {
    const validator: Record<string, YupString> = {};
    AppConfig.LANGUAGES.forEach((lang) => {
        validator[lang] = yupValidator;
        validator[AppConfig.DEFAULT_LANG] = (yupValidator as YupString).test(
            'requiredValidatorTest',
            __('error.fieldIsRequired'),
            (_: StringField, context: ContextType) => {
                if (!context.parent[AppConfig.DEFAULT_LANG]?.length) {
                    return false;
                }
                return true;
            },
        );
    });
    return yup.object().shape(validator);
};

export const optionalI18nValidator = (yup: YupType, yupValidator: YupString): YupObject => {
    const validator: Record<string, YupString> = {};
    AppConfig.LANGUAGES.forEach((lang) => {
        validator[lang] = yupValidator;
    });
    return yup.object().shape(validator);
};
