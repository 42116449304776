import { createStandaloneToast } from '@chakra-ui/react';
import { theme } from '../../../config/theme';

type toastStatuses = 'success' | 'error' | 'info' | 'warning';

export const notification = (title: string, status: toastStatuses): void => {
  const toast = createStandaloneToast({ theme });

  toast({
    title,
    status,
    duration: 5000,
    isClosable: true,
    position: 'top',
    variant: 'left-accent',
  });
};
