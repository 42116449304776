import { FC } from 'react';
import { Input, Textarea } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { __ } from '../../../helpers/i18n';
import { useFormFieldContext } from '../../../hooks/form/useFormFieldContext';
import { BaseInputProps } from '../../../types/forms';
import { formFieldFontSizes, formFieldPaddings } from '../../../config/defaultConfigProps';

interface Props extends BaseInputProps {
  defaultValue?: string;
  pwd?: string | boolean;
  isHidden?: boolean;
  textarea?: boolean;
  noResize?: boolean;
}

export const InputText: FC<Props> = ({
  defaultValue,
  onChange,
  disabled,
  placeholder,
  pwd,
  textarea,
  noResize,
  isHidden,
  ...props
}) => {
  const { name, id } = useFormFieldContext();
  const { register, formState, trigger } = useFormContext();
  const { onChange: fieldOnChange, ...rest } = register(name);
  return textarea ? (
    <Textarea
      {...rest}
      name={name}
      id={id}
      onChange={async (e) => {
        await fieldOnChange(e);
        onChange?.();
      }}
      disabled={disabled}
      defaultValue={defaultValue}
      placeholder={placeholder && __(placeholder)}
      fontSize={formFieldFontSizes}
      resize={noResize ? 'none' : 'vertical'}
    />
  ) : (
    <Input
      {...rest}
      type={pwd ? 'password' : isHidden ? 'hidden' : 'text'}
      name={name}
      id={id}
      onChange={async (e) => {
        await fieldOnChange(e);
        if (typeof pwd === 'string' && formState.dirtyFields[pwd]) {
          await trigger(pwd);
        }
        onChange?.(e);
      }}
      disabled={disabled}
      defaultValue={defaultValue}
      placeholder={placeholder && __(placeholder)}
      fontSize={formFieldFontSizes}
      p={formFieldPaddings}
      {...props}
    />
  );
};
