import * as yup from 'yup';
import { checkPassword, checkRepeatedPassword } from '../../helpers/yupValidators';

export const schema = yup.object().shape({
    password: checkPassword(yup),
    password2: checkRepeatedPassword(yup),
    updateToken: yup.string(),
    userId: yup.string(),
});

export const getSchema = () => yup.object().shape({
    password: checkPassword(yup),
    password2: checkRepeatedPassword(yup),
    updateToken: yup.string(),
    userId: yup.string(),
});