import { OfferFiles } from '../../src/offer/entities/offer-files.entity';
import { Offer } from '../../src/offer/offer.entity';
import { CategoryDetails, FullRecipeResponse, I18nEntry } from '../../types';

export interface Percentages {
	[key: string]: number;
}
export interface FieldValue {
	fieldId: string;
	value: string | number | boolean;
}

export interface Quantities {
	[key: string]: number;
}

export interface Prices {
    [key: string]: number;
}

export interface Visibilities {
	[key: string]: boolean;
}

export interface Values {
	[key: string]: string | number | boolean | null;
}

export interface OfferEntity {
	id: string;
	status: OfferStatus;
	offerNumber: string;
	totalValue: number;
	totalCost: number;
    totalWeight: number;
	createDate: Date;
	client: OfferClient;
	files: OfferFiles[];
	offerExpiryDate: Date;
	contactPerson: ContactPerson;
	recipe: OfferRecipeDetails;
	investmentLocation: string;
	descriptionOfferIncluded: string;
	descriptionOfferNotIncluded: string;
	groupDetailsType: OfferClientGroupDetailsType;
	groups: OfferGroupDetails[];
    taxRate: number;
}

export interface ShareOfferReq {
	validTo: Date;
}

export interface ShareOfferResponse {
	accessToken: string;
	offerId: string;
	validToDate: Date;
}

export interface OfferFilesEntity {
	id: string;
	offerFiles: string;
	offer: Offer;
}

export interface OfferFilesDetails {
	id: string;
	name: string;
}

export interface CreateOfferReq {
	id?: string;
	client: OfferClient;
	offerType: string[];
	objectType: string;
	offerExpiryDate: Date | number;
	contactPerson: ContactPerson;
	descriptionOfferIncluded: string;
	investmentLocation: string;
	descriptionOfferNotIncluded: string;
	groups: OfferGroupDetails[];
	recipe: OfferRecipeDetails;
	groupDetailsType: OfferClientGroupDetailsType;
}

export interface OfferGroupDetails {
	id: string;
	name: I18nEntry;
	description?: I18nEntry;
	services: OfferServiceDetails[];
	components: OfferComponentDetails[];
	products: OfferProductDetails[];
    position: number;
    // price is calculated before sending to front end
    price?: number;
}
export interface OfferForClientGroupDetails {
    id: string;
	name: I18nEntry;
	description?: I18nEntry;
	services: OfferServiceForClient[];
	components: OfferComponentForClient[];
	products: OfferProductForClient[];
    price: number;
    position: number;
}

export interface OfferForClientGroupLimited {
    id: string;
	name: I18nEntry;
	description?: I18nEntry;
	price: number;
    position: number;
}

export interface OfferRecipeDetails {
	recipe: FullRecipeResponse;
	calculationUuid: string;
	sectionVisibility: Visibilities;
	groupVisibility: Visibilities;
	infoValues: Values;
	infoVisibility: Visibilities;
	itemInactivity: Visibilities;
	fieldValues: Values;
	fieldOptions: Values;
	fieldVisibility: Visibilities;
	quantities: Quantities;
	itemVisibility: Visibilities;
	markUp: Percentages;
    buyPrices: Prices;
}
export interface OfferFilesElement {
	name: string;
	id: string;
}

export interface OfferDetails {
	id: string;
	status: OfferStatus;
	offerNumber: string;
	totalValue?: number;
	totalCost?: number;
    totalWeight?: number;
	valForSqMeter?: number;
	createDate: Date;
	client: OfferClient;
	offerExpiryDate: Date;
	contactPerson: ContactPerson;
	objectType: string;
	offerType: string[];
	recipe: OfferRecipeDetails;
	offerFiles?: OfferFilesDetails[];
	investmentLocation: string;
	groups: OfferGroupDetails[];
	descriptionOfferIncluded: string;
	descriptionOfferNotIncluded: string;
	groupDetailsType: OfferClientGroupDetailsType;
}

export interface OfferDetailsRaw {
	id: string;
	status: OfferStatus;
	offerNumber: string;
	totalValue?: number;
	totalCost?: number;
    totalWeight?: number;
	valForSqMeter?: number;
	createDate: Date;
	client: string; //json - OfferClient;
	offerExpiryDate: Date;
	contactPerson: string; //json - ContactPerson;
	objectType: string;
	offerType: string; // json - string []
	recipe: OfferRecipeDetails;
	offerFiles?: OfferFilesDetails[];
	investmentLocation: string;
	groups: OfferGroupDetails[];
	descriptionOfferIncluded: string;
	descriptionOfferNotIncluded: string;
	creator: string;
}

export interface OfferUnit {
	id: string;
	name: I18nEntry;
	type: string;
}

export interface OfferComponentDetails {
	id: string;
	name: I18nEntry;
	active: boolean;
	position?: number;
	markUp: number;
	quantity: number;
	services: OfferServiceDetails[];
	products: OfferProductDetails[];
	isAdditional?: boolean;
    // component price is calculated while fetching and returned to front end
    price?: number;
}

export interface OfferComponentForClient {
	id: string;
	name: I18nEntry;
	active: boolean;
	position?: number;
	quantity: number;
	services: OfferServiceForClient[];
	products: OfferProductForClient[];
	isAdditional?: boolean;
    price: number;
}

export interface OfferProductDetails {
	id: string;
	name: I18nEntry;
	description: I18nEntry;
	unit: OfferUnit;
	position: number;
	category: CategoryDetails;
	producer: string;
	netPrice: number;
    weight: number;
	region?: string;
	city?: string;
	markUp: number;
	length?: number;
	active: boolean;
	quantity: number;
	currentPrice: number;
	buyPrice: number;
	isAdditional?: boolean;
}

export interface OfferProductForClient {
	id: string;
	name: I18nEntry;
	position: number;
	description: I18nEntry;
	unit: OfferUnit;
	length?: number;
	singlePrice: number;
	price: number;
	quantity: number;
	isAdditional?: boolean;
}

export interface OfferServiceForClient {
	id: string;
	name: I18nEntry;
	description: I18nEntry;
	position: number;
	unit: OfferUnit;
	length?: number;
	price: number;
    singlePrice: number;
	quantity: number;
	isAdditional?: boolean;
}

export interface OfferForClient {
	validToDate: Date;
	id: string;
	status: OfferStatus;
	offerNumber: string;
	totalValue: number;
	createDate: Date;
	client: OfferClient;
	offerExpiryDate: Date;
	contactPerson: ContactPerson;
	objectType: string;
	offerType: string[];
	investmentLocation: string;
	offerFiles: OfferFilesDetails[];
	groups: OfferForClientGroupDetails[] | OfferForClientGroupLimited[];
	descriptionOfferIncluded: string;
	descriptionOfferNotIncluded: string;
	groupDetailsType: OfferClientGroupDetailsType;
}

export interface ChangeOfferStatusReq {
	newStatus: OfferStatus;
	reason?: string;
}

export interface CsvOfferStatistics {
	client: string;
	location: string;
	objectType: string;
	industry: string;
	totalValue: number;
	totalCost: number;
    totalWeight: number;
	author: string;
	comments: string;
}

export interface ShareOfferResponse {
	accessToken: string;
	offerId: string;
	validToDate: Date;
}

export interface OfferServiceDetails {
	id: string;
	name: I18nEntry;
	markUp: number;
	position: number;
	description: I18nEntry;
	length?: number;
	category: CategoryDetails;
	unit: OfferUnit;
	active: boolean;
	quantity: number;
	cost: number;
	currentCost: number;
	netPrice: number;
	isAdditional?: boolean;
}

export interface OfferClient {
	id?: string;
	name: string;
	industry: string;
	createdAt?: Date;
	internalId: string;
	city: string;
	country: string;
	postalCode: string;
	street: string;
	streetNumber: string;
	addressAdditionalInfo: I18nEntry;
}

export interface ContactPerson {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	email: string;
}
export enum OfferStatus {
	NEW,
	INPROGRESS,
	ACCEPTED,
	CANCELLED,
	FINISHED,
}

export enum ItemType {
	PRODUCT,
	SERVICE,
}

export enum ObjectType {
	NOTSPECIFY,
}

export enum OfferType {
	NOTSPECIFY,
}

export interface ChangeOfferStatusReq {
	newStatus: OfferStatus;
	reason?: string;
}

export enum OfferClientGroupDetailsType {
	FULL,
	LIMITED,
}
