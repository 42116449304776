import { FC } from 'react';
import { Stack as ChakraStack, StackProps } from '@chakra-ui/layout';

export const StackColumn: FC<StackProps> = ({ children, ...props }) => (
    <ChakraStack
        flexDir='column'
        justifyContent='center'
        alignItems='center'
        {...props}
    >
        {children}
    </ChakraStack>
  );
  