import axios, { AxiosRequestConfig } from 'axios';
import { apiUrl } from './apiUrl';
import { redirectToLogin } from '../services/api';

const REQ_TIMEOUT = 20000;

export const axiosRequestConfiguration: AxiosRequestConfig = {
  baseURL: apiUrl,
  withCredentials: true,
  timeout: REQ_TIMEOUT,
};

export const axiosRequestConfigurationWithoutCredentials: AxiosRequestConfig = {
  baseURL: apiUrl,
  withCredentials: false,
  timeout: REQ_TIMEOUT,
};

axios.interceptors.request.use(
  (c) => c,
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401) {
      redirectToLogin();
    }
    return error;
  },
);
