import { FC, useCallback, useEffect, useState } from 'react';
import { TableResponse } from '../../../../../../../../pol-met-back/tables';
import { State } from '../../../../../../config/store';
import { useApi } from '../../../../../../hooks/api/useApi';
import { BaseReactSelectOption } from '../../../../../../types/forms';
import { getCurrentFromI18nEntry } from '../../../../../../utils/language';
import { FormField } from '../../../../../FormField';
import { FormStack } from '../../../../../FormField/FormStack';
import { InputSelectSearchable } from '../../../../../FormField/InputSelectSearchable';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { ServiceDetails } from 'pol-met-types';

export const ConfiguratorModalServiceForm: FC = () => {
  const {
    lang,
  } = useSelector((state: State) => state);
  const [servicesData, setServicesData] = useState<ServiceDetails[]>([]);
  const { get } = useApi();
  const { setValue, trigger } = useFormContext();

  useEffect(() => {
    const getServices = async () => {
      const res = await get<TableResponse<ServiceDetails>>(`/service/list`);
      if (res) setServicesData(res.items);
    };
    getServices();
  }, []);

  const getOptions = useCallback(
    async (inputValue: string, callback: Function) => {
      const res = await get<TableResponse<ServiceDetails>>(
        `/service/list?page=1&searchBy=name&searchQuery=${inputValue}&searchOperators=0`
      );
      if (res) {
        return callback(
          res.items?.map((item: ServiceDetails) => {
            return {
              label: getCurrentFromI18nEntry(item.name, lang.current),
              value: item,
            };
          })
        );
      }
    },
    []
  );

  const getServiceById = async (serviceId: string) => {
    const res = await get<ServiceDetails>(
      `/service/${serviceId}`
    );
    if (res || res === null) {
      setValue('service', res);
      trigger('service');
    }
  };

  return (
    <FormStack>
      <FormField labelTxt='labels.service' name='serviceSelect' width='full'>
        <InputSelectSearchable
          isClearable={false}
          promiseOptions={(value: string, cb: Function) =>
            getOptions(value, cb)
          }
          defaultOptions={servicesData?.map((el: ServiceDetails) => ({
            label: getCurrentFromI18nEntry(el.name, lang.current),
            value: el
          })) || []}
          handleChange={(el: BaseReactSelectOption) => {
            getServiceById(el.value.id);
          }}
        />
      </FormField>
    </FormStack>
  );
};
