import * as yup from 'yup';
import { priceNumber, numberInput, reactSelectOption, requiredI18nValidator } from '../../helpers/yupValidators';
import { __ } from '../../helpers/i18n';

export const schema = yup.object().shape({
    name: requiredI18nValidator(
        yup,
        yup.string()
            .max(120, __('error.textToLong', { value: 120 }))
    ),
    description: requiredI18nValidator(
        yup,
        yup.string()
            .max(1000, __('error.textToLong', { value: 1000 }))
    ),
    unit: yup.string().required(__('error.fieldIsRequired')),
    position: yup
        .number().nullable().required(__('error.fieldIsRequired')),
    category: yup.string().required(__('error.fieldIsRequired')),
    producer: yup.string().required(__('error.fieldIsRequired')),
    voivodeship: yup.string(),
    city: yup.string().nullable(),
    region: yup.string().nullable(),
    buyPrice: priceNumber(yup)
        .max(1000000, __('error.numberToBig', { value: 1000000 }))
        .required(__('error.fieldIsRequired')),
    currentPrice: priceNumber(yup)
        .max(1000000, __('error.numberToBig', { value: 1000000 }))
        .required(__('error.fieldIsRequired')),
    markUp: numberInput(yup)
        .integer(__('error.mustBeInteger'))
        .min(0, __('error.numberToSmall', { value: 0 }))
        .max(100, __('error.numberToBig', { value: 100 })),
    producerSelect: reactSelectOption(yup),
    categorySelect: reactSelectOption(yup),
    location: yup.boolean(),
});
