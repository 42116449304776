import { I18nEntry } from 'pol-met-types';
import CONFIG from '../config/app';
import { isJsonString } from './is-json';

export const getClientLanguage = (): string => {
  const language = window.navigator.language.slice(0, 2).toLowerCase();
  if (CONFIG.LANGUAGES.find((lang: string) => lang === language)) return language;
  return CONFIG.DEFAULT_LANG;
};

export const getCurrentFromI18nEntry = (entry: I18nEntry | undefined, language: string) => {
  if (!entry) return '';

  const json = isJsonString(entry.toString());
  if (json) entry = json;
  return (
    entry && entry[language] ||
    entry && entry[CONFIG.DEFAULT_LANG] ||
    (entry && typeof entry !== 'object' ? entry : '') ||
    ''
  );
};

// Zobacz i18nInputs.ts
export const createI18nEntryObject = (keys: Array<string>, values: any, keysToKeep: Array<string>) => {
  const langs: Array<string> = CONFIG.LANGUAGES;
  let obj: any = {};
  for (let key of keys) {
    for (let lang of langs) {
      obj[key] = {
        ...obj[key],
        [lang]: values[key + lang] || null
      }
    }
  }
  for (let keyTK of keysToKeep) {
    obj[keyTK] = values[keyTK];
  }
  return obj;
};

export const getI18nEntryObject = (keys: Array<string>, values: any, keysToKeep: Array<string>) => {
  if (typeof values === 'object' && !Array.isArray(values)) {
    const langs: Array<string> = CONFIG.LANGUAGES;
    let obj: any = {};
    for (let key of keys) {
      for (let lang of langs) {
        obj[key + lang] = values[key][lang]
      }
    }
    for (let keyTK of keysToKeep) {
      obj[keyTK] = values[keyTK];
    }
    return obj;
  }
  return values;
};
