import { FC } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalContentProps,
} from '@chakra-ui/react';
import { defaultModalWidth } from '../../../../config/defaultConfigProps';

interface Props extends ModalContentProps {
  isOpen: boolean;
  closeOnOverlayClick?: boolean;
  onClose: () => void;
  modalWidth?: Array<string> | string | null;
}

const BasicModal: FC<Props> = ({ isOpen, title, modalWidth, closeOnOverlayClick, children, onClose, ...props }) => (
  <Modal motionPreset="slideInBottom" onClose={onClose} isOpen={isOpen} isCentered closeOnOverlayClick={closeOnOverlayClick}>
    <ModalOverlay />
    <ModalContent w={modalWidth ? modalWidth : defaultModalWidth} maxW="90vw" maxH="90vh" overflowY="auto" {...props}>
      {title && <ModalHeader pr={'5rem'}>{title}</ModalHeader>}
      <ModalCloseButton />
      <ModalBody
        maxH='80vh'
        overflowY='auto'
      >{children}</ModalBody>
    </ModalContent>
  </Modal>
);

export default BasicModal;
