import { FC } from 'react';
import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    NumberInputFieldProps,
} from '@chakra-ui/react';
import { useFormContext, Controller } from 'react-hook-form';
import { __ } from '../../../helpers/i18n';
import { useFormFieldContext } from '../../../hooks/form/useFormFieldContext';
import { formFieldFontSizes, formFieldPaddings } from '../../../config/defaultConfigProps';

interface Props extends NumberInputFieldProps {
    min?: number;
    defaultValue?: number;
    placeholder?: string;
    isDisabled?: boolean;
    withStepper?: boolean | number;
}

export const InputNumber: FC<Props> = ({
    min,
    defaultValue,
    placeholder,
    withStepper,
    isDisabled,
    ...props
}) => {
    const { name, id } = useFormFieldContext();
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={Number.isNaN(defaultValue) ? null : defaultValue}
            render={({ field, ...fieldProps }) => (
                <NumberInput
                    name={name}
                    id={id}
                    value={field.value ?? ''}
                    onChange={(e) => {
                        field.onChange(e === '' ? null : e);
                    }}
                    step={typeof withStepper === 'number' ? withStepper : 1}
                    min={min ?? undefined}
                >
                    <NumberInputField
                        ref={field.ref}
                        placeholder={placeholder && __(placeholder)}
                        fontSize={formFieldFontSizes}
                        p={formFieldPaddings}
                        {...props}
                    />
                    {withStepper && (
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    )}
                </NumberInput>
            )}
        />
    );
};
