import { FC } from 'react';
import { Flex as FlexChakra, FlexProps } from '@chakra-ui/layout';
import { borderRadius } from '../../../../config/defaultConfigProps';

export const FlexAuth: FC<FlexProps> = ({ children, ...props }) => (
    <FlexChakra
        flexDir={['column', 'column', 'row', 'row']}
        alignItems='center'
        justifyContent='center'
        backgroundColor='mainBg'
        width="100%"
        maxW="700px"
        p={['2rem', '2rem', '2rem 2rem 2rem 0', '2rem 2rem 2rem 0']}
        boxShadow={'0 0px 30px -10px rgba(0, 0, 0, 0.1)'}
        borderRadius={borderRadius}
        {...props}
    >
        {children}
    </FlexChakra>
);