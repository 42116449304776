import { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { UseFormReturn } from 'react-hook-form';
import { initializeAxios } from '../../services/api';
import { axiosRequestConfiguration, axiosRequestConfigurationWithoutCredentials } from '../../config/axios';
import { defaultDelay } from '../../config/defaultConfigProps';
import { handleError } from '../../helpers/handleError';
import { ApiResult } from 'pol-met-types';
import { errorNotification } from '../../helpers/handleNotifications';
import { getErrorMessage } from '../../types/getErrorMessage';

export type ApiReqFnBasic = <T>(
  url: string,
  queryParams?: Record<string, string>,
  withoutCredentials?: boolean,
  isFile?: boolean,
) => Promise<void | T>;
export type ApiReqFn = <T, K>(
  url: string,
  body?: K | undefined,
  queryParams?: Record<string, string>,
) => Promise<void | T>;
export type ApiReqDelete = <T>(url: string, id: string) => Promise<void | T>;

interface UseApi {
  inProgress: boolean;
  isError: boolean;
  get: ApiReqFnBasic;
  post: ApiReqFn;
  postFormData: ApiReqFn;
  put: ApiReqFn;
  patch: ApiReqFn;
  deleteRow: ApiReqDelete;
}

interface Props {
  methods?: UseFormReturn<any>;
  delay?: number;
}

export type ApiPayload<T> = { payload: T, result: number, error_code?: number };
interface ApiResponse<T> extends AxiosResponse<ApiPayload<T>> {
  isAxiosError?: boolean;
}

let axiosInstance = initializeAxios(axiosRequestConfiguration);

const returnPayload = <T extends unknown>(
  res: ApiResponse<T>,
  withoutCredentials?: boolean,
  isFile?: boolean,
) => {
  if (withoutCredentials) {
    return res.data as T;
  } else if (isFile) {
    saveAs(res.data as unknown as Blob, res.headers?.filename ?? 'file');
    return res.data as T;
  } else {
    return res.data?.payload;
  }
};

export const useApi = (props?: Props): UseApi => {
  const [inProgress, setInProgress] = useState(false);
  const [isError, setIsError] = useState(false);
  const delay = props?.delay ?? defaultDelay;

  const get = useCallback(
    <T extends unknown>(
      url: string,
      queryParams?: Record<string, string>,
      withoutCredentials?: boolean,
      isFile?: boolean,
    ) => {
      if (withoutCredentials) {
        axiosInstance = initializeAxios(axiosRequestConfigurationWithoutCredentials);
      }
      if (isFile) {
        axiosInstance = initializeAxios({ ...axiosRequestConfiguration, responseType: 'blob' });
      }
      setInProgress(true);
      setIsError(false);
      return axiosInstance
        .get<ApiPayload<T>>(url, { params: queryParams })
        .then((res: ApiResponse<T>) => {
          if (res?.isAxiosError) throw res;
          if (res?.data.result === ApiResult.error) errorNotification(getErrorMessage(res.data.error_code || 0));
          return returnPayload<T>(res, withoutCredentials, isFile);
        })
        .catch((err) => {
          setTimeout(() => {
            setInProgress(false);
            setIsError(true);
            handleError(err.response, props?.methods?.setError);
          }, delay);
        })
        .finally(() => {
          setInProgress(false);
          if (withoutCredentials) {
            axiosInstance = initializeAxios(axiosRequestConfiguration);
          }
        });
    },
    [],
  );

  const post = useCallback(<T, K>(url: string, body?: K, queryParams?: Record<string, string>) => {
    setInProgress(true);
    setIsError(false);
    return axiosInstance
      .post<ApiPayload<T>>(url, body, { params: queryParams })
      .then((res: ApiResponse<T>) => {
        if (res?.isAxiosError) throw res;
        if (res?.data.result === ApiResult.error) errorNotification(getErrorMessage(res.data.error_code || 0));
        return res.data.payload;
      })
      .catch((err) => {
        setTimeout(() => {
          setIsError(true);
          setInProgress(false);
          handleError(err.response, props?.methods?.setError);
        }, delay);
      })
      .finally(() => {
        setTimeout(() => {
          setInProgress(false);
        }, delay);
      });
  }, []);

  const postFormData = useCallback(<T, K>(url: string, body?: K, queryParams?: Record<string, string>) => {
    setInProgress(true);
    setIsError(false);
    return axiosInstance
      .post<ApiPayload<T>>(url, body, {
        params: queryParams,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res: ApiResponse<T>) => {
        if (res?.isAxiosError) throw res;
        if (res?.data.result === ApiResult.error) errorNotification(getErrorMessage(res.data.error_code || 0));
        return res.data.payload;
      })
      .catch((err) => {
        setTimeout(() => {
          setIsError(true);
          setInProgress(false);
          handleError(err.response, props?.methods?.setError);
        }, delay);
      })
      .finally(() => {
        setTimeout(() => {
          setInProgress(false);
        }, delay);
      });
  }, []);

  const put = useCallback(<T, K>(url: string, body?: K, queryParams?: Record<string, string>) => {
    setInProgress(true);
    setIsError(false);
    return axiosInstance
      .put<ApiPayload<T>>(url, body, { params: queryParams })
      .then((res: ApiResponse<T>) => {
        if (res?.isAxiosError) throw res;
        if (res?.data.result === ApiResult.error) errorNotification(getErrorMessage(res.data.error_code || 0));
        return res.data.payload;
      })
      .catch((err) => {
        setTimeout(() => {
          setInProgress(false);
          setIsError(true);
          handleError(err.response, props?.methods?.setError);
        }, delay);
      })
      .finally(() => {
        setTimeout(() => {
          setInProgress(false);
        }, delay);
      });
  }, []);

  const patch = useCallback(<T, K>(url: string, body?: K, queryParams?: Record<string, string>) => {
    setInProgress(true);
    setIsError(false);
    return axiosInstance
      .patch<ApiPayload<T>>(url, body, { params: queryParams })
      .then((res: ApiResponse<T>) => {
        if (res?.isAxiosError) throw res;
        if (res?.data.result === ApiResult.error) errorNotification(getErrorMessage(res.data.error_code || 0));
        return res.data.payload;
      })
      .catch((err) => {
        setTimeout(() => {
          setInProgress(false);
          setIsError(true);
          handleError(err.response, props?.methods?.setError);
        }, delay);
      })
      .finally(() => {
        setTimeout(() => {
          setInProgress(false);
        }, delay);
      });
  }, []);

  const deleteRow = useCallback(<T extends unknown>(url: string, id: string) => {
    setInProgress(true);
    setIsError(false);
    return axiosInstance
      .delete<ApiPayload<T>>(`${url}/${id}`)
      .then((res: ApiResponse<T>) => {
        if (res?.isAxiosError) throw res;
        if (res?.data.result === ApiResult.error) {
          errorNotification(getErrorMessage(res.data.error_code || 0));
          return undefined;
        }
        return res.data.payload;
      })
      .catch((err) => {
        setTimeout(() => {
          setInProgress(false);
          setIsError(true);
          handleError(err.response, props?.methods?.setError);
        }, delay);
      })
      .finally(() => {
        setTimeout(() => {
          setInProgress(false);
        }, delay);
      });
  }, []);

  return {
    get,
    post,
    postFormData,
    patch,
    put,
    deleteRow,
    inProgress,
    isError,
  };
};
