import { FC } from 'react';
import { HStack as HStackChakra, StackProps } from "@chakra-ui/layout";
import { formFieldSpacing } from '../../../config/defaultConfigProps';

export const FormStack: FC<StackProps> = ({children, ...props}) => {

    return (
        <HStackChakra
            spacing={formFieldSpacing}
            flexDirection={{ base: 'column', md: 'row' }}
            width={{ base: 'full', md: 'auto' }}
            {...props}
        >
            {children}
        </HStackChakra>
    );
};