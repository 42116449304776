import { FC } from 'react';
import {
    Box as BoxChakra, BoxProps,
} from '@chakra-ui/react';
import { Logo } from '../../Logo';

export const LogoBoxAuth: FC<BoxProps> = ({  children, ...props }) => (
    <BoxChakra
        width={['150px', '180px', '220px', '300px']}
        margin={['auto auto 2rem auto', 'auto auto 2rem auto', 'auto', 'auto']}
        {...props}
    >
        <Logo width='80%' m='auto' />
    </BoxChakra>
);
