import React from 'react';
import {
    Image
} from '@chakra-ui/react';
import {
    Text,
} from '@chakra-ui/layout';
import { __ } from '../../helpers/i18n';
import { Button } from '../Common/Button';
import { Link } from 'react-router-dom';
import { FlexFullscreenCentered } from '../Common/Flex/FlexFullscreenCentered';

const PageNotFound = () => (
    <FlexFullscreenCentered>
        <Image maxWidth='200px' src='/assets/logo.png' alt='logo' />
        <Text m='2rem 0'>
            {__('application.sorryToNotFoundRequestedUrl')}
        </Text>
        <Link to='/'>
            <Button
                type='button'
                variant='main'
            >
                {__('application.homePage')}
            </Button>
        </Link>
    </FlexFullscreenCentered>
);

export default PageNotFound;