import { FC } from 'react';
import { Text as ChakraText, TextProps } from '@chakra-ui/react';
import { __ } from '../../../helpers/i18n';
import { formFieldFontSizes } from '../../../config/defaultConfigProps';

interface Props extends TextProps {
  text?: string;
  isNormal?: boolean;
}

export const Text: FC<Props> = ({ text, children, isNormal, ...props }) => {

  if (isNormal)
    return <ChakraText fontSize={[13, 14, 15, 16]} color='text' {...props}>
      {text && __(text)}
      {children}
    </ChakraText>

  return (
    <ChakraText fontSize={[13, 14, 15, 16]} color='paragraph' {...props}>
      {text && __(text)}
      {children}
    </ChakraText>
  )
};
