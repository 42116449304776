import { FC } from 'react';
import { Spinner, Center as CenterChakra, CenterProps } from '@chakra-ui/react';

interface AppSpinnerInterface extends CenterProps {
  fullScreen?: boolean;
  overlay?: boolean;
}

export const AppSpinner: FC<AppSpinnerInterface> = ({ fullScreen, overlay, ...props }) => (
  <CenterChakra width={fullScreen ? '100vw' : '100%'} height={fullScreen ? '100vh' : '100%'} {...props}
    position={overlay ? 'absolute' : 'relative'}
    zIndex={999}
    backgroundColor={overlay ? 'rgba(255, 255, 255, 0.5)' : 'transparent'}>
    <Spinner size="xl" color="main" />
  </CenterChakra>
);
