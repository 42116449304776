import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { ModalButtonsPosition } from '../types/modal';
import { __ } from '../helpers/i18n';

export interface modalData {
    idToDelete?: string;
    url?: string;
}

export interface AppModalState {
    title?: string;
    content?: string | ReactNode;
    cancelText?: string;
    submitText?: string;
    isOpened: boolean;
    isResolved?: boolean;
    isSubmitted?: boolean;
    isRejected?: boolean;
    buttonsPosition?: ModalButtonsPosition;
    data?: modalData | null;
    modalWidth?: Array<string> | string | null;
    callback?: () => void;
}

interface SetContentProps {
    title: string;
    content: string | ReactNode;
    buttonsPosition?: ModalButtonsPosition;
    cancelText?: string;
    submitText?: string;
    data?: modalData | null;
    modalWidth?: Array<string> | string | null;
}

const initialState: AppModalState = {
    title: '',
    content: '',
    cancelText: __('action.cancel'),
    submitText: __('action.submit'),
    isOpened: false,
    isResolved: false,
    isSubmitted: false,
    isRejected: false,
    data: null,
    buttonsPosition: ModalButtonsPosition.right,
    modalWidth: null,
};

const appModalSlice = createSlice({
    name: 'appModal',
    initialState,
    reducers: {
        setIsOpened: (state, action) => {
            state.isOpened = action.payload;
        },
        setAfterResolving: (state) => {
            state.isResolved = true;
            state.isSubmitted = true;
        },
        setAfterRejected: (state) => {
            state.isResolved = true;
            state.isRejected = true;
        },
        setContent: (state, action: PayloadAction<SetContentProps>) => {
            if (action.payload.title) state.title = action.payload.title;
            if (action.payload.content) state.content = action.payload.content;
            if (action.payload.modalWidth) state.modalWidth = action.payload.modalWidth;
            if (action.payload.buttonsPosition) state.buttonsPosition = action.payload.buttonsPosition;
            if (action.payload.data) state.data = action.payload.data;
            if (action.payload.cancelText) state.cancelText = action.payload.cancelText;
            if (action.payload.submitText) state.submitText = action.payload.submitText;
        },
        clearResolving: () => initialState,
    },
});

export const { setIsOpened, setAfterResolving, setContent, clearResolving, setAfterRejected } =
    appModalSlice.actions;

export default appModalSlice.reducer;
