import { I18nEntry } from 'pol-met-types';


export function isJsonString(str: any) {
  if(!str) return false;
  try {
    return JSON.parse(str.toString());
  } catch (e) {
    return false;
  }
}

export function getJSONFromString(str: string | I18nEntry) {
  if(!str || typeof str !== 'string') return '';
  try {
    return JSON.parse(str);
  } catch (e) {
    return '';
  }
};

export function getJSONFromStringElseArray(str: string) {
  if(!str) return [];
  try {
    return JSON.parse(str);
  } catch (e) {
    return [];
  }
};
