import { Recipe } from 'src/recipe/entities/recipe.entity';
import { Block } from '../blocks';
import { I18nEntry } from '../i18n';

export interface RecipeInfoEntity {
	id: string;
	type: RecipeIntoType;
	name: I18nEntry;
	position: number;
	image?: string;
	value?: Block[];
	condition?: Block[];
	recipe: Recipe;
}

export interface RecipeInfoRequest {
	name: I18nEntry;
	type: RecipeIntoType;
	position: number;
	recipe: string;
	value?: Block[];
	condition?: Block[];
}

export interface OfferInfo {
	id: string;
	type: RecipeIntoType;
	position: number;
	name: I18nEntry;
	recipe: Recipe;
	value: string | number | boolean | null;
	image?: string;
}

export interface RecipeInfoDetails {
	id: string;
	type: RecipeIntoType;
	name: I18nEntry;
	position: number;
	image: string;
	value?: Block[];
	condition?: Block[];
}

export enum RecipeIntoType {
	TEXT,
	NUMBER,
	IMAGE,
}
