export enum BlockType {
	IF,
	ADD,
	SUBSTRACT,
	MULTIPLE,
	DIVIDE,
	VALUE,
	TEXT,
	FIELD,
	GROUP,
	EQUALS,
	LESSER_THAN,
	LESSER_OR_EQUAL,
	MORE_THAN,
	MORE_OR_EQUAL,
	AND,
	OR,
	TRUE,
	FALSE,
	NULL,
	DIFFERENT,
	ROUND,
	ROUND_DOWN,
	ROUND_UP,
	NOT,
	FIELD_NAME,
	TEXT_I18N,
	DESIGN_X,
	POWER,
	ROOT,
    RADIO_OPTION
}

interface BlockArray {
	[key: number]: Omit<BlockForRhf, ''>;
}
// React Hook Form not support circular reference. This will be fixed in v8 version
export interface BlockForRhf {
	type: BlockType;
	uuid: string;
	value?: string | number | {};
	blocks?: BlockArray[];
	denyDelete?: boolean;
}

export interface Block {
	type: BlockType;
	uuid: string;
	value?: string | number | {};
	blocks?: Block[];
	denyDelete?: boolean;
}
