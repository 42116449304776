import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { App } from './components/App/App';
import './index.scss';

Sentry.init({
  dsn: "https://61b5d5e232af405995e3e67cf69dbcc3@sentry.etd24.pl/113",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <App />,
  document.getElementById('root')
);