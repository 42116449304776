import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { __ } from '../../helpers/i18n';

export interface AdminModalState {
  title: string;
  content: string | ReactNode;
  cancelText: string;
  submitText: string;
  isOpened: boolean;
  isResolved: boolean;
  isSubmitted: boolean;
  isRejected: boolean;
}

interface SetContentProps {
  title: string;
  content: string | ReactNode;
  cancelText?: string;
  submitText?: string;
}

const initialState: AdminModalState = {
  title: '',
  content: '',
  cancelText: __('action.cancel'),
  submitText: __('action.submit'),
  isOpened: false,
  isResolved: false,
  isSubmitted: false,
  isRejected: false,
};

const adminModalSlice = createSlice({
  name: 'adminModal',
  initialState,
  reducers: {
    setIsOpened: (state, action) => {
      state.isOpened = action.payload;
    },
    setAfterResolving: (state) => {
      state.isResolved = true;
      state.isSubmitted = true;
    },
    setAfterRejected: (state) => {
      state.isResolved = true;
      state.isRejected = true;
    },
    setContent: (state, action: PayloadAction<SetContentProps>) => {
      state.title = action.payload.title;
      state.content = action.payload.content;
      if (action.payload.cancelText) state.cancelText = action.payload.cancelText;
      if (action.payload.submitText) state.submitText = action.payload.submitText;
    },
    clearResolving: (state) => initialState,
  },
});

export const { setIsOpened, setAfterResolving, setContent, clearResolving, setAfterRejected } =
  adminModalSlice.actions;

export default adminModalSlice.reducer;
