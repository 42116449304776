import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import BasicModal from '../../../BasicModal';
import * as yup from 'yup';
import { __ } from '../../../../../../helpers/i18n'; import { RecipeGroupEntity } from 'pol-met-types';
import ConfiguratorOfferProduct from './Product';
import ConfiguratorOfferService from './Service';
import ConfiguratorOfferComponent from './Component';
import ConfiguratorOfferNewProduct from './NewProduct';
import ConfiguratorOfferNewService from './NewService';

import { schema as newProductSchema } from '../../../../../../formsSchemas/products/product';


export enum CONFIGURATOR_OFFER_MODAL_TYPES {
    PRODUCTS = 'PRODUCTS',
    NEW_PRODUCT = 'NEW_PRODUCT',
    SERVICES = 'SERVICES',
    NEW_SERVICE = 'NEW_SERVICE',
    COMPONENTS = 'COMPONENTS',
}

interface Props {
    handleClose: () => void;
    type: CONFIGURATOR_OFFER_MODAL_TYPES | null;
    group: RecipeGroupEntity;
}

const getTitle = (type: CONFIGURATOR_OFFER_MODAL_TYPES | null) => {
    switch (type) {
        case CONFIGURATOR_OFFER_MODAL_TYPES.PRODUCTS: return __('labels.addProduct');
        case CONFIGURATOR_OFFER_MODAL_TYPES.NEW_PRODUCT: return __('labels.addProduct');
        case CONFIGURATOR_OFFER_MODAL_TYPES.SERVICES: return __('labels.addService');
        case CONFIGURATOR_OFFER_MODAL_TYPES.NEW_SERVICE: return __('labels.addService');
        case CONFIGURATOR_OFFER_MODAL_TYPES.COMPONENTS: return __('labels.addComponent');
        default: return '';
    }
}

const getSchema = (type: CONFIGURATOR_OFFER_MODAL_TYPES | null) => {
    switch (type) {
        case CONFIGURATOR_OFFER_MODAL_TYPES.SERVICES: return yup.object().shape({
            service: yup.object().required(__('error.fieldIsRequired')),
            quantity: yup.number().nullable().required(__('error.fieldIsRequired')),
        });
        case CONFIGURATOR_OFFER_MODAL_TYPES.COMPONENTS: return yup.object().shape({
            component: yup.object().required(__('error.fieldIsRequired')),
            quantity: yup.number().nullable().required(__('error.fieldIsRequired')),
        });
        case CONFIGURATOR_OFFER_MODAL_TYPES.NEW_PRODUCT: return newProductSchema;
        default: return yup.object().shape({
            product: yup.object().required(__('error.fieldIsRequired')),
            quantity: yup.number().nullable().required(__('error.fieldIsRequired')),
        });
    }
};


const ConfiguratorOfferModal: FC<Props> = ({ handleClose, type, group }) => {
    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(getSchema(type)),
        defaultValues: {
            quantity: 1,
        }
    });


    const handleCloseBtn = (data?: any) => {
        methods.reset();
        handleClose();
    };

    const checkIsOpen = () => {
        return !!type && [
            CONFIGURATOR_OFFER_MODAL_TYPES.COMPONENTS,
            CONFIGURATOR_OFFER_MODAL_TYPES.NEW_PRODUCT,
            CONFIGURATOR_OFFER_MODAL_TYPES.NEW_SERVICE,
            CONFIGURATOR_OFFER_MODAL_TYPES.PRODUCTS,
            CONFIGURATOR_OFFER_MODAL_TYPES.SERVICES
        ].includes(type);

    };

    const getContent = () => {
        switch (type) {
            case CONFIGURATOR_OFFER_MODAL_TYPES.PRODUCTS: return <ConfiguratorOfferProduct handleClose={handleClose} group={group} />;
            case CONFIGURATOR_OFFER_MODAL_TYPES.NEW_PRODUCT: return <ConfiguratorOfferNewProduct handleClose={handleClose} group={group} />;
            case CONFIGURATOR_OFFER_MODAL_TYPES.SERVICES: return <ConfiguratorOfferService handleClose={handleClose} group={group} />;
            case CONFIGURATOR_OFFER_MODAL_TYPES.NEW_SERVICE: return <ConfiguratorOfferNewService handleClose={handleClose} group={group} />;
            case CONFIGURATOR_OFFER_MODAL_TYPES.COMPONENTS: return <ConfiguratorOfferComponent handleClose={handleClose} group={group} />;
            default: return '';
        }
    }

    return (
        <BasicModal
            isOpen={checkIsOpen()}
            title={getTitle(type)}
            whiteSpace='pre-line'
            onClose={() => handleCloseBtn(methods.getValues())}
            closeOnOverlayClick={false}
        >
            {getContent()}
        </BasicModal>
    );
};

export default ConfiguratorOfferModal;
