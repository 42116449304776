import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CONFIG from '../config/app';

export interface LangState {
  toggled: boolean;
  current: string;
  available: string[];
}

const initialState: LangState = {
  toggled: false,
  current: CONFIG.DEFAULT_LANG,
  available: ['pl'],
};

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    toggleLangList: (state: LangState) => {
      state.toggled = !state.toggled;
    },
    hideLangList: (state) => {
      state.toggled = false;
    },
    changeLanguage: (state, action: PayloadAction<string>) => {
      state.toggled = false;
      state.current = action.payload;
      window.location.reload();
    },
  },
});

export const { toggleLangList, hideLangList, changeLanguage } = langSlice.actions;

export default langSlice.reducer;
