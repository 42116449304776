import { FC } from 'react';
import { Text as ChakraText, TextProps } from '@chakra-ui/react';
import { __ } from '../../../../helpers/i18n';
import { Link } from 'react-router-dom';

interface Props extends TextProps {
  text: string;
  to: string;
}

export const TextLink: FC<Props> = ({ text, to, children, ...props }) => (
  <ChakraText fontSize={['1.1rem', '1.3rem', '1.4rem', '1.5rem']} color='text' {...props}>
    <Link to={to}>{text && __(text)}</Link>
    {children}
  </ChakraText>
);
