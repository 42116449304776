export const defaultDelay = 300;
export const borderRadius = '0.2rem';
export const sidebarWidth = '260px';
export const mainRGBA = (opacity: number) => `rgba(11, 130, 209, ${opacity})`

// HEADERS
export const headerBaseFontSizes = ['1.2rem', '1.3rem', '1.4rem', '1.5rem', '1.6rem', '1.7rem'];
export const headerTabFontSizes = ['1.1rem', '1.2.rem', '1.3rem', '1.5rem'];

// BUTTONS
export const buttonFontSizes = ['1rem', '1.2rem', '1.4rem', '1.6rem'];
export const buttonSmallHeights = ['20px', '25px', '30px', '35px'];
export const navBtnBg = '#EFF0F1';
// INPUTS SELECTS
export const globalFilterFontSizes = ['0.8rem', '1rem', '1.2rem', '1.4rem'];
export const headerH3FontSizes = ['1.4rem', '1.6rem', '1.8rem', '2rem'];
export const formFieldFontSizes = ['1rem', '1.2rem', '1.4rem', '1.6rem'];
export const formFieldPaddings = ['1rem', '1.2rem', '1.4rem', '1.6rem'];
export const formFieldSelectHeights = ['2.5rem', '2.6rem', '3rem', '3.5rem'];
export const formFieldSpacing = ['0', '0', '1.5rem', '2rem'];
export const formFieldLabelFontSizes = ['1rem', '1.1rem', '1.3rem', '1.5rem'];
export const formFieldErrorMessageFontSizes = ['0.9rem', '1rem', '1.1rem', '1.2rem'];
export const colorPickerBoxPaddings = ['0.1rem', '0.2rem', '0.3rem', '0.4rem'];

// TABLE
export const defaultGap = 2;
export const tableHeaderFontSizes = ['0.8rem', '1rem', '1.1rem', '1.2rem'];
export const tableFontSizes = ['0.8rem', '1rem', '1.2rem', '1.4rem'];

// MODAL
export const defaultModalWidth = '448px';

// ICONS
export const tagIconBoxSizes = ['0.7rem', '0.8rem', '0.9rem', '1rem'];
