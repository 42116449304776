import { FC } from 'react';
import { Box as BoxChakra, BoxProps } from '@chakra-ui/react';


export const BoxForm: FC<BoxProps> = ({ children, ...props }) => (
    <BoxChakra
        backgroundColor='mainBg'
        p={['1.5rem 0.5rem', null, '1.5rem 3rem']}
        {...props}
    >
        {children}
    </BoxChakra>
);