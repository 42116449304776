import { FC } from 'react';
import * as yup from 'yup';
import { RecipeGroupEntity, ServiceDetails } from 'pol-met-types';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BoxForm } from '../../../../../Box/BoxForm';
import { HStack, Button } from '@chakra-ui/react';
import { __ } from '../../../../../../../helpers/i18n';
import { ConfiguratorModalServiceForm } from '../../../../../../FormContent/Offer/FormCalculation/Modal/Service';
import { FormField } from '../../../../../../FormField';
import { FormStack } from '../../../../../../FormField/FormStack';
import { InputNumber } from '../../../../../../FormField/InputNumber';
import { useDispatch } from 'react-redux';
import { addServiceToOffer } from '../../../../../../../slices/configurator/visualisationSlice';

const schema = yup.object().shape({
  service: yup.object().required(__('error.fieldIsRequired')),
  quantity: yup.number().nullable().required(__('error.fieldIsRequired')),
});

interface ConfiguratorOfferServiceTypes {
  quantity: number;
  service: ServiceDetails;
}

interface Props {
  handleClose: () => void;
  group: RecipeGroupEntity;
}

const ConfiguratorOfferService: FC<Props> = ({ handleClose, group }) => {
  const methods = useForm<ConfiguratorOfferServiceTypes>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      quantity: 1,
    }
  });
  const dispatch = useDispatch();

  const submit = (data: ConfiguratorOfferServiceTypes) => {
    dispatch(addServiceToOffer({
      quantity: data.quantity,
      service: data.service,
      group,
    }));
    methods.reset();
    handleClose();
  };

  const handleCloseBtn = () => {
    methods.reset();
    handleClose();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)}>
        <BoxForm>
          <ConfiguratorModalServiceForm />
          <FormStack>
            <FormField labelTxt='labels.quantity' name='quantity' width={'full'}>
              <InputNumber withStepper min={0} placeholder='placeholders.enterData' />
            </FormField>
          </FormStack>
        </BoxForm>

        <HStack spacing='1rem' justifyContent={'center'} mb='1rem'>
          <Button type='submit' variant='main' disabled={!methods?.formState.isValid}>
            {__('action.submit')}
          </Button>
          <Button type='button' variant='main' onClick={handleCloseBtn}>
            {__('action.cancel')}
          </Button>
        </HStack>
      </form>
    </FormProvider>
  );
};

export default ConfiguratorOfferService;
