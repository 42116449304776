import { FC } from 'react';
import { Center } from '@chakra-ui/react';
import { __ } from '../../../../helpers/i18n';

export const AppOffline: FC = () => (
    <Center height="100vh" textAlign="center">
        <div>
            {/* TODO: Add logo */}
            {/* <Logo /> */}
            <h1>
                <strong>{__('error.youAreUsingThisAppInOfflineMode')}</strong>
            </h1>
            <p>{__('error.connectToInternetToAccessApp')}</p>
        </div>
    </Center>
);
