import { FC } from 'react';
import * as yup from 'yup';
import { RecipeGroupEntity } from 'pol-met-types';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BoxForm } from '../../../../../Box/BoxForm';
import { HStack, Button } from '@chakra-ui/react';
import { __ } from '../../../../../../../helpers/i18n';
import { FormField } from '../../../../../../FormField';
import { InputNumber } from '../../../../../../FormField/InputNumber';
import { InputText } from '../../../../../../FormField/InputText';
import { useDispatch } from 'react-redux';
import { addNewProductToOffer } from '../../../../../../../slices/configurator/visualisationSlice';

const schema = yup.object().shape({
    quantity: yup.number().nullable().required(__('error.fieldIsRequired')),
    weight: yup.number().nullable().required(__('error.fieldIsRequired')),
    netPrice: yup.number().nullable().required(__('error.fieldIsRequired')),
    markUp: yup.number().nullable().required(__('error.fieldIsRequired')),
    name: yup.string().required(__('error.fieldIsRequired')),
});

interface ConfiguratorOfferNewProductTypes {
    name: string;
    markUp: number;
    quantity: number;
    netPrice: number;
    weight: number;
}

interface Props {
    handleClose: () => void;
    group: RecipeGroupEntity;
}

const ConfiguratorOfferProduct: FC<Props> = ({ handleClose, group }) => {
    const methods = useForm<ConfiguratorOfferNewProductTypes>({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            quantity: 1,
        }
    });
    const dispatch = useDispatch();

    const submit = (data: ConfiguratorOfferNewProductTypes) => {
        dispatch(addNewProductToOffer({
            name: data.name,
            netPrice: data.netPrice,
            quantity: data.quantity,
            weight: data.weight,
            markUp: data.markUp,
            group,
        }));
        methods.reset();
        handleClose();
    };

    const handleCloseBtn = () => {
        methods.reset();
        handleClose();
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submit)}>
                <BoxForm>
                    {/* <FormStack> */}
                        <FormField labelTxt='labels.name' name='name' width={'full'}>
                            <InputText placeholder='labels.name' />
                        </FormField>
                        <FormField labelTxt='labels.quantity' name='quantity' width={'full'}>
                            <InputNumber withStepper min={0} placeholder='placeholders.enterData' />
                        </FormField>
                        <FormField labelTxt='labels.buyPrice' name='netPrice' width={'full'}>
                            <InputNumber withStepper min={0} placeholder='placeholders.enterData' />
                        </FormField>
                        <FormField labelTxt='labels.markUp' name='markUp' width={'full'}>
                            <InputNumber withStepper min={0} placeholder='placeholders.enterData' />
                        </FormField>
                        <FormField labelTxt='labels.weight' name='weight' width={'full'}>
                            <InputNumber withStepper min={0} placeholder='placeholders.enterData' />
                        </FormField>
                      
                    {/* </FormStack> */}
                </BoxForm>

                <HStack spacing='1rem' justifyContent={'center'} mb='1rem'>
                    <Button type='submit' variant='main' disabled={!methods?.formState.isValid}>
                        {__('action.submit')}
                    </Button>
                    <Button type='button' variant='main' onClick={handleCloseBtn}>
                        {__('action.cancel')}
                    </Button>
                </HStack>
            </form>
        </FormProvider>
    );
};

export default ConfiguratorOfferProduct;
